<script>
import { required, email } from "vuelidate/lib/validators";
import {
  authMethods,
  authFackMethods,
  notificationMethods,
} from "@/state/helpers";
import { mapState } from "vuex";
import appConfig from "@/app.config";

/**
 * Auth-Register component
 */
export default {
  page: {
    title: "Register",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      user: { username: "", email: "", password: "" },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
    };
  },
  computed: {
    ...mapState("authfack", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  validations: {
    user: {
      username: { required },
      email: { required, email },
      password: { required },
    },
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to register the user in with the email, username
    // and password they provided.
    tryToRegisterIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          this.tryingToRegister = true;
          // Reset the regError if it existed.
          this.regError = null;
          return (
            this.register({
              email: this.user.email,
              password: this.user.password,
            })
              // eslint-disable-next-line no-unused-vars
              .then((token) => {
                this.tryingToRegister = false;
                this.isRegisterError = false;
                this.registerSuccess = true;
                if (this.registerSuccess) {
                  this.$router.push(
                    this.$route.query.redirectFrom || { name: "home" }
                  );
                }
              })
              .catch((error) => {
                this.tryingToRegister = false;
                this.regError = error ? error : "";
                this.isRegisterError = true;
              })
          );
        } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
          const { email, username, password } = this.user;
          if (email && username && password) {
            this.registeruser(this.user);
          }
        }
      }
    },
  },
};
</script>

<template>
  <div class="account-pages my-5 pt-sm-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          <div class="text-center mb-5">
            <router-link to="/" class="auth-logo">
              <img
                src="@/assets/images/logo-dark.png"
                alt=""
                height="28"
                class="auth-logo-dark"
              />
              <img
                src="@/assets/images/logo-light.png"
                alt=""
                height="28"
                class="auth-logo-light"
              />
            </router-link>
            <p class="font-size-15 text-muted mt-3">
              Responsive <b>Bootstrap 5</b> Admin Dashboard
            </p>
          </div>
          <div class="card overflow-hidden">
            <div class="row g-0">
              <div class="col-lg-6">
                <div class="p-lg-5 p-4">
                  <div>
                    <h5>Register account</h5>
                    <p class="text-muted">Get your free Samply account now.</p>
                  </div>

                  <div class="mt-4 pt-3">
                    <b-alert
                      v-model="registerSuccess"
                      class="mt-3"
                      variant="success"
                      dismissible
                      >Registration successfull.</b-alert
                    >

                    <b-alert
                      v-model="isRegisterError"
                      class="mt-3"
                      variant="danger"
                      dismissible
                      :show="notificationAutoCloseDuration"
                      @dismissed="clear"
                      >{{ regError }}</b-alert
                    >

                    <div
                      v-if="notification.message"
                      :class="'alert alert-' + notification.type"
                    >
                      {{ notification.message }}
                    </div>

                    <form @submit.prevent="tryToRegisterIn">
                      <div class="mb-3">
                        <label class="fw-semibold" for="username"
                          >Username</label
                        >
                        <input
                          v-model="user.username"
                          type="text"
                          class="form-control"
                          id="username"
                          :class="{
                            'is-invalid': submitted && $v.user.username.$error,
                          }"
                          placeholder="Enter username"
                        />
                        <div
                          v-if="submitted && !$v.user.username.required"
                          class="invalid-feedback"
                        >
                          Username is required.
                        </div>
                      </div>
                      <div class="mb-3">
                        <label class="fw-semibold" for="useremail">Email</label>
                        <input
                          v-model="user.email"
                          type="email"
                          class="form-control"
                          id="useremail"
                          placeholder="Enter email"
                          :class="{
                            'is-invalid': submitted && $v.user.email.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.user.email.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.user.email.required"
                            >Email is required.</span
                          >
                          <span v-if="!$v.user.email.email"
                            >Please enter valid email.</span
                          >
                        </div>
                      </div>
                      <div class="mb-3">
                        <label class="fw-semibold" for="userpassword"
                          >Password</label
                        >
                        <input
                          v-model="user.password"
                          type="password"
                          class="form-control"
                          id="userpassword"
                          placeholder="Enter password"
                          :class="{
                            'is-invalid': submitted && $v.user.password.$error,
                          }"
                        />
                        <div
                          v-if="submitted && !$v.user.password.required"
                          class="invalid-feedback"
                        >
                          Password is required.
                        </div>
                      </div>

                      <div class="mt-4 text-end">
                        <button
                          class="btn btn-primary w-md waves-effect waves-light"
                          type="submit"
                        >
                          Register
                        </button>
                      </div>

                      <div class="mt-4 text-center">
                        <p class="mb-0 text-muted">
                          By registering you agree to the Samply
                          <a
                            href="javascript:void(0);"
                            class="text-primary fw-semibold text-decoration-underline"
                            >Terms of Use</a
                          >
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="p-lg-5 p-4 bg-auth h-100 d-none d-lg-block">
                  <div class="bg-overlay"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- end card -->
          <div class="mt-5 text-center">
            <p>
              Already have an account ?
              <router-link
                to="/auth/login-1"
                class="fw-semibold text-decoration-underline"
              >
                Login
              </router-link>
            </p>
            <p>
              © {{ new Date().getFullYear() }} <b>Samply</b>. Crafted with
              <i class="mdi mdi-heart text-danger"></i> by Pichforest
            </p>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </div>
  <!-- end account page -->
</template>
